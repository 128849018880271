<template>
  <div class="header-wrap">
    <el-header class="header">
      <div class="title"><img :src="setting.logo_url" alt=""></div>
      <div class="title-addon">
        <div class="menu-bar">
          <el-menu :default-active="active" class="el-menu-demo" mode="horizontal" :unique-opened="true" router>
            <el-menu-item index="/home">Home</el-menu-item>
            <!-- <el-menu-item index="/category">Category</el-menu-item> -->
             <el-submenu index="2">
              <template slot="title">Category</template>
              <div class="submenu-box">
                <el-row>
                  <el-col :span="10">
                    <div class="submenu-box-left">
                        <h3>Click for details</h3>
                        <div class="submenu-box-leftList">
                          <el-menu-item index="category" :index="item.url_name" :route="{ path: '/category', query: { category: item.url_name } }"  v-for="(item,index0) in category" :key="index0" @mouseover.native="hover(index0)"  :class="item.index0 ? now:'' ">
                          {{item.category_name}}
                          </el-menu-item>
                        </div>
                    </div>
                  </el-col>
                  <el-col :span="14">
                      <div class="submenu-box-right">
                        <ul class="submenu-box-rUl">
                          <li v-for="(item,index1) in category" :key="index1" v-show="on==index1">
                            <img :src="item.banner_url" alt="" >
                          </li>
                        </ul>
                          
                      </div>
                  </el-col>
                </el-row>
                
              </div>
              
            </el-submenu>
            <el-menu-item index="/service">Service</el-menu-item>
            <el-menu-item index="/project">Project</el-menu-item>
            <el-menu-item index="/blog">Blog</el-menu-item>
            <el-menu-item index="/about">About Us</el-menu-item>
            <el-menu-item index="/contact">Contact</el-menu-item>
			<el-menu-item id="translate" class="langage"></el-menu-item>
          </el-menu>
        </div>
      
      </div>
    </el-header>
  </div>
</template>

<script>
import { web_info } from '@/api/setting'
import { category } from '@/api/category'


export default {
  data() {
    return {
      setting: [],
      category: [],
	  url_name: this.$route.query.category,
      on:0,
    }
  },
   
		computed: {
      
			active() {
				//console.log(this.$route)
				return this.$route.matched[0].path
			}
		},
  created() {
    this.setting_info();
	this.headercategory();
  },
  mounted() {
    
  },
  methods:{

    setting_info(){
      web_info().then(res => {
        this.setting=res.data.data
		document.title=this.setting.title;
		document.querySelector('meta[name="keywords"]').setAttribute('content', this.setting.keywords);
		document.querySelector('meta[name="description"]').setAttribute('content', this.setting.description);
      }).catch(() => {})
    },
	// 分类选择
    headercategory() {
      category().then(res => {
        this.category = res.data.data
      }).catch(() => {})
    },

    hover(index0){
      this.on = index0
    },

  }
}

</script>

<style scoped>
  .header-wrap{
      padding: 30px;
      background-color: #fff;
  }

  .header {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.title {
		height: 60px;
	}

  .title img{
    height: 100%;
  }

	.title-addon {
		display: flex;
		line-height: 60px;
	}
	
  .header /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title,
  .header /deep/ .el-menu--horizontal>.el-menu-item {
    font-size: 1.15vw;
    color: #000;
    border: none;
  
  }
	
	.header /deep/ .el-menu--horizontal>.el-submenu:focus .el-submenu__title, 
	.header /deep/ .el-menu--horizontal>.el-submenu:hover .el-submenu__title,
  .header /deep/ .el-menu--horizontal>.el-submenu.is-active .el-submenu__title,
	.header /deep/ .el-menu--horizontal>.el-menu-item:hover,
	.header /deep/ .el-menu--horizontal>.el-menu-item.is-active {
	  color: #d93540;
	}
	
 	
	.header /deep/ .el-menu.el-menu--horizontal {
		border: none;
	}
  
  .submenu-box{
    width: 100%;
    box-sizing: border-box;
    padding: 2% 8.3vw;
    overflow: hidden;
  }

  .submenu-box-rUl,
  .submenu-box-rUl li,
  .submenu-box-rUl li img{
    width: 100%;
    vertical-align: top;
  }


  .submenu-box-rUl li img{
    height: 26vw;
  }

  .submenu-box-left h3{
    color: #000;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 25px;
    font-weight: normal;
  }

  .submenu-box-leftList .el-menu-item{
    width: 48%;
    float: left;
    margin-bottom: 4%;
    color: #013656;
    font-size: 0.9325vw;
  }


  .submenu-box-leftList .el-menu-item.is-active,
  .submenu-box-leftList .el-menu-item.now,
  .submenu-box-leftList .el-menu-item:hover{
    text-decoration: underline;
    font-weight: bold;
  }





  @media all and (max-width:1366px){
    .header-wrap{
      padding: 10px;
    }
  }
</style>
<style>
.el-menu--collapse .el-menu .el-submenu, 
.el-menu--popup {
    min-width: calc(100vw - 17px)  !important;
}

</style>
