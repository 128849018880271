import request from '@/utils/request'
const url = '/api/setting/'

// ----------------评价----------------
export function web_info(params) {
  return request({
    url: url + 'info',
    method: 'get',
    params: params
  })
}
