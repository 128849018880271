<template>
  
<div class="i-footer-wrap">
    <div class="i-footer">
        <div class="i-footer-left">
            <div class="i-footer-logo">
                <img :src="setting.footer_logo_url" alt="">
            </div>
            <p>{{setting.tel}}</p>
            <p>{{setting.email}}</p>
            <div class="footer-share-box">
                <h4>Follow Our Social Media:</h4>
                <div class="footer-share">
                <el-row>
                    <el-button circle>
                    <a target="_blank" href="https://www.facebook.com/Starmax-Hotel-Supply-104299345689644">
					<img src="https://api.starmaxintl.com/storage/file/20220825/3cde53ef97614025cfbd53eafdabc3bff98fd3bf.png" alt=""/>
					</a>
                    </el-button>
                    <el-button circle>
                    <a target="_blank" href="https://www.instagram.com/starmaxsupply/">
					<img src="https://api.starmaxintl.com/storage/file/20220825/94ab0280cc99fa67961ba50bea4d0e541039b032.png" alt=""/>
					</a>
                    </el-button>
                    <el-button circle>
					<a target="_blank" href="https://www.youtube.com/channel/UCUj3G5cTW6b77LcjRoqkItg">
                    <img src="https://api.starmaxintl.com/storage/file/20220825/c49b0bcad8f00acbb1f7b2be8eb4f4a0c3d41acf.png" alt=""/>
					</a>
                    </el-button>
                    <el-button circle>
					<a target="_blank" href="https://www.tiktok.com/@supplyforhotel?is_from_webapp=1&sender_device=pc">
                    <img src="https://api.starmaxintl.com/storage/file/20220825/57eb20e700b775e1a2d7b7c9537ec7d7dfa39a95.png" alt=""/>
					</a>
                    </el-button>
                    <el-button circle>
					<a target="_blank" href="https://twitter.com/Starmaxsupply">
                    <img src="https://api.starmaxintl.com/storage/file/20220825/5f3b95fc3ef93abb9eea3ce8d536307b79e54be4.png" alt=""/>
					</a>
                    </el-button>
                    <el-button circle>
					<a target="_blank" href="https://www.linkedin.com/company/starmax-hotel-supply/">
                    <img src="https://api.starmaxintl.com/storage/file/20220825/09666f9bbed2db336ae3eed39c167b4861232848.png" alt=""/>
					</a>
                    </el-button>
                </el-row>
                
                </div>
            </div>
        </div>
        <div class="i-footer-right">
            <el-row>
                <el-col :span="6">
                    <p><router-link to="/home">Home</router-link></p>
                    <p><router-link to="/faq">Faq</router-link></p>
                    <p><router-link to="/blog">Blog</router-link></p>
                    <p><router-link to="/about">About Us</router-link></p>
                    <p><router-link to="/project">Our Project</router-link></p>
                    <p><router-link to="/service">Service</router-link></p>
                    <p><router-link to="">Download</router-link></p>
                    <p><router-link to="/contact">Contact Us</router-link></p>
                </el-col>
                <el-col :span="6">
                    <p><a target="_blank" href="https://www.instagram.com/starmaxsupply/">Instagram</a></p>
                    <p><a target="_blank" href="https://www.facebook.com/Starmax-Hotel-Supply-104299345689644">Facebook</a></p>
                    <p><a target="_blank" href="https://www.linkedin.com/company/starmax-hotel-supply/">Linkedin</a></p>
                    <p><a target="_blank" href="https://www.pinterest.com/STARMAXforhotel/">Pinterest</a></p>
                    <p><a target="_blank" href="https://wa.me/message/TI6OW5NAXEDTF1">Whatsapp</a></p>
                </el-col>
                <el-col :span="6">
                    <p v-for="(item,index1) in category1" :key="index1"><router-link :to="{ path: '/category', query: { category: item.url_name } }">{{item.category_name}}</router-link></p>
                </el-col>
                <el-col :span="6">
                    <p v-for="(item,index2) in category2" :key="index2"><router-link :to="{ path: '/category', query: { category: item.url_name } }">{{item.category_name}}</router-link></p>
                </el-col>
            </el-row>
        </div>
    </div>
<el-backtop style="width:50px;height:50px;">
  <div style=" height: 100%; width: 100%; background: #013656 ;  box-shadow: 0 0 6px rgba(0,0,0, .12); text-align: center; z-index: 9999; " >
    <img src="https://api.starmaxintl.com/storage/file/20220715/e75486d409260e76f43ab4aafd176fd88b17e6a8.png" alt="" style="vertical-align: top;">
  </div>
</el-backtop>
</div>
</template>

<script>
import { web_info } from '@/api/setting'
import { category } from '@/api/category'


  export default {
    data() {
      return {
	    setting: [],
        category1: [],
		category2: [],
      }
    },
	created() {
      this.setting_info();
	  this.footercategory();
    },
	mounted() {
	 
	// translate.setUseVersion2();
	//translate.includedLanguages = 'es,ar,en'
	// translate.execute();
	},
    computed: {
    },
    methods:{
	  setting_info(){
      web_info().then(res => {
        this.setting=res.data.data
      }).catch(() => {})
      },
	  // 分类选择
		footercategory() {
		  category({'page':1}).then(res => {
			this.category1 = res.data.data
		  }).catch(() => {})
		  category({'page':2}).then(res => {
			this.category2 = res.data.data
		  }).catch(() => {})
		},
    }
  }
</script>
<!--<script src="http://res.zvo.cn/translate/translate.js"></script>-->

<style scoped>
.i-footer-wrap{
    padding: 60px 8.3vw;
    border-top: 1px solid #dcdde1;
    overflow: hidden;
}

.i-footer{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
}

.i-footer-left{
    width: 500px;
   
}

.i-footer-logo{
    width: 100%;
}

.i-footer-logo img{
    height: 60px;
}

.i-footer-left p{
    line-height: 30px;
    color: #013656;
    font-size: 18px;
    margin-top: 15px;
}

.footer-share-box{
    margin-top: 30px;
}

.footer-share-box h4{
    line-height: 30px;
    color: #013656;
    font-size: 20px;
    margin-bottom: 20px;
    font-family: DINCound;
}

.footer-share{
    width: 100%;
    overflow: hidden;
}

.footer-share .el-button{
    border: 0;
    padding: 0;
    transition: 1s all;
}

.footer-share .el-button:hover{
    transform: rotate(360deg);
}

.footer-share .el-button img{
    width: 54px;
    height: 54px;
}


.footer-share .el-button+.el-button{
    margin-left: 20px;
}


.i-footer-right{
    flex: 1;
}

.i-footer-right p{
    margin-bottom: 15px;
    line-height: 30px;
}

.i-footer-right p:hover{
    text-decoration: underline;
}

.i-footer-right a,
.i-footer-right p .el-link{
    color: #013656;
    font-size: 0.9325vw;
}
</style>
